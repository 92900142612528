/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;600&display=swap');
@import '~@angular/cdk/overlay-prebuilt.css';
@import url(https://fonts.googleapis.com/css?family=Lato:700);
.ribbon {
  width: 190px;
  height: 190px;
  overflow: hidden;
  position: absolute;
  z-index: 9999;
}
.ribbon::before,
.ribbon::after {
  position: absolute;
  z-index: -1;
  content: '';
  display: block;
  border: 5px solid #2980b9;
}
.ribbon span {
  position: absolute;
  display: block;
  width: 275px;
  padding: 15px 0;
  box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
  color: #fff;
  font: 700 15px/1 "Lato", sans-serif;
  text-shadow: 0 1px 1px rgb(0 0 0 / 20%);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-blue {
    background-color: #3498db;
}
.ribbon-green {
    background-color: #178f57;
}
  /* top left*/
.ribbon-top-left {
    top: -10px;
    left: -10px;
  }
  .ribbon-top-left::before,
  .ribbon-top-left::after {
    border-top-color: transparent;
    border-left-color: transparent;
  }
  .ribbon-top-left::before {
    top: 0;
    right: 0;
  }
  .ribbon-top-left::after {
    bottom: 0;
    left: 0;
  }
  .ribbon-top-left span {
    right: -20px;
    top: 58px;
    transform: rotate(-45deg);
  }
  /* top right*/
.ribbon-top-right {
    top: -10px;
    right: -10px;
  }
  .ribbon-top-right::before,
  .ribbon-top-right::after {
    border-top-color: transparent;
    border-right-color: transparent;
  }
  .ribbon-top-right::before {
    top: 0;
    left: 0;
  }
  .ribbon-top-right::after {
    bottom: 0;
    right: 0;
  }
  .ribbon-top-right span {
    left: -25px;
    top: 30px;
    transform: rotate(45deg);
  }