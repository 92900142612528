@charset "UTF-8";
/* 1. Core */
/* ----------------------------------------------------------------------------------------------------- */
/* @ Any configuration we need from Tailwind's config file will be extracted here so we can import this
/* @ file from "config.ts" to access the extracted configuration from TypeScript
/* ----------------------------------------------------------------------------------------------------- */
@variants fuse-tailwind-extracted-config {}
/* 2. Components */
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Example viewer
/* ----------------------------------------------------------------------------------------------------- */
.example-viewer {
  display: flex;
  flex-direction: column;
  margin: 32px 0;
  overflow: hidden;
  @apply rounded-2xl shadow bg-card;
}
.example-viewer .title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  min-height: 88px;
  max-height: 88px;
  padding: 0 40px;
}
.example-viewer .title h6 {
  font-weight: 700;
}
.example-viewer .title .controls {
  display: flex;
  align-items: center;
}
.example-viewer .title .controls > * + * {
  margin-left: 8px;
}
.example-viewer mat-tab-group .mat-tab-body-content .fuse-highlight pre {
  margin: 0;
  border-radius: 0;
}

input,
textarea {
  background: transparent;
  /* Placeholder color */
}
input::placeholder,
textarea::placeholder {
  @apply text-hint;
}
input::-moz-placeholder,
textarea::-moz-placeholder {
  @apply text-hint;
}
input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  @apply text-hint;
}
input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  @apply text-hint;
}
input:-webkit-autofill,
textarea:-webkit-autofill {
  -webkit-transition: "background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
input:-webkit-autofill:hover,
textarea:-webkit-autofill:hover {
  -webkit-transition: "background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
input:-webkit-autofill:focus,
textarea:-webkit-autofill:focus {
  -webkit-transition: "background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}
input:-webkit-autofill:active,
textarea:-webkit-autofill:active {
  -webkit-transition: "background-color 9999s ease-out";
  -webkit-transition-delay: 9999s;
}

/* 3. Overrides */
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Angular Material CDK helpers & overrides
/* ----------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Overlay
/* ----------------------------------------------------------------------------------------------------- */
.fuse-backdrop-on-mobile {
  @apply bg-black bg-opacity-60 sm:bg-transparent !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Angular Material helpers & overrides
/* ----------------------------------------------------------------------------------------------------- */
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Accordion
/* ----------------------------------------------------------------------------------------------------- */
.mat-accordion .mat-expansion-panel {
  margin-bottom: 24px;
  border-radius: 8px !important;
  transition: box-shadow 225ms cubic-bezier(0.4, 0, 0.2, 1);
  @apply shadow !important;
}
.mat-accordion .mat-expansion-panel:last-child {
  margin-bottom: 0;
}
.mat-accordion .mat-expansion-panel.mat-expanded, .mat-accordion .mat-expansion-panel:hover {
  @apply shadow-lg !important;
}
.mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-keyboard-focused, .mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]).cdk-program-focused, .mat-accordion .mat-expansion-panel:not(.mat-expanded) .mat-expansion-panel-header:not([aria-disabled=true]):hover {
  background: transparent !important;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header {
  font-size: 14px;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header[aria-disabled=true] .mat-expansion-panel-header-description {
  margin-right: 28px;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  /* Do not override the border color of the expansion panel indicator */
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-header .mat-expansion-indicator:after {
  border-color: currentColor !important;
}
.mat-accordion .mat-expansion-panel .mat-expansion-panel-body {
  line-height: 1.7;
  @apply text-secondary !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Buttons
/* ----------------------------------------------------------------------------------------------------- */
.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
  line-height: 1 !important;
  /* Large button */
  /* Lower the icon opacity on disabled buttons */
}
.mat-button .mat-button-wrapper,
.mat-fab .mat-button-wrapper,
.mat-flat-button .mat-button-wrapper,
.mat-icon-button .mat-button-wrapper,
.mat-mini-fab .mat-button-wrapper,
.mat-raised-button .mat-button-wrapper,
.mat-stroked-button .mat-button-wrapper {
  position: relative;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
  z-index: 2;
  /* Move mat-button-wrapper above the ripple and focus overlay */
}
.mat-button .mat-button-focus-overlay,
.mat-button .mat-button-ripple,
.mat-fab .mat-button-focus-overlay,
.mat-fab .mat-button-ripple,
.mat-flat-button .mat-button-focus-overlay,
.mat-flat-button .mat-button-ripple,
.mat-icon-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-ripple,
.mat-mini-fab .mat-button-focus-overlay,
.mat-mini-fab .mat-button-ripple,
.mat-raised-button .mat-button-focus-overlay,
.mat-raised-button .mat-button-ripple,
.mat-stroked-button .mat-button-focus-overlay,
.mat-stroked-button .mat-button-ripple {
  z-index: 1;
}
.mat-button.fuse-mat-button-large,
.mat-fab.fuse-mat-button-large,
.mat-flat-button.fuse-mat-button-large,
.mat-icon-button.fuse-mat-button-large,
.mat-mini-fab.fuse-mat-button-large,
.mat-raised-button.fuse-mat-button-large,
.mat-stroked-button.fuse-mat-button-large {
  height: 48px;
  min-height: 48px;
  max-height: 48px;
}
.mat-button.mat-button-disabled .mat-icon,
.mat-fab.mat-button-disabled .mat-icon,
.mat-flat-button.mat-button-disabled .mat-icon,
.mat-icon-button.mat-button-disabled .mat-icon,
.mat-mini-fab.mat-button-disabled .mat-icon,
.mat-raised-button.mat-button-disabled .mat-icon,
.mat-stroked-button.mat-button-disabled .mat-icon {
  opacity: 0.38 !important;
}

.mat-fab {
  max-height: 56px;
}

/* Rounded design */
.mat-button,
.mat-flat-button,
.mat-raised-button,
.mat-stroked-button {
  padding: 0 20px !important;
  border-radius: 9999px !important;
}

/* Target all buttons */
.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-fab,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
  /* mat-progress-spinner inside buttons */
}
.mat-button .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle,
.mat-fab .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle,
.mat-flat-button .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle,
.mat-icon-button .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle,
.mat-fab .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle,
.mat-mini-fab .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle,
.mat-raised-button .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle,
.mat-stroked-button .mat-progress-spinner.mat-progress-spinner-indeterminate-animation[mode=indeterminate] circle {
  stroke: currentColor !important;
  animation-duration: 6000ms;
}

/* Colored background buttons */
.mat-flat-button,
.mat-raised-button,
.mat-fab,
.mat-mini-fab {
  /* Add hover and focus style on all buttons */
  /* On palette colored buttons, use a darker color */
}
.mat-flat-button .mat-icon,
.mat-raised-button .mat-icon,
.mat-fab .mat-icon,
.mat-mini-fab .mat-icon {
  color: currentColor !important;
}
.mat-flat-button .mat-button-focus-overlay,
.mat-raised-button .mat-button-focus-overlay,
.mat-fab .mat-button-focus-overlay,
.mat-mini-fab .mat-button-focus-overlay {
  @apply bg-gray-400 bg-opacity-20 dark:bg-black dark:bg-opacity-5 !important;
}
.mat-flat-button.mat-primary .mat-button-focus-overlay, .mat-flat-button.mat-accent .mat-button-focus-overlay, .mat-flat-button.mat-warn .mat-button-focus-overlay,
.mat-raised-button.mat-primary .mat-button-focus-overlay,
.mat-raised-button.mat-accent .mat-button-focus-overlay,
.mat-raised-button.mat-warn .mat-button-focus-overlay,
.mat-fab.mat-primary .mat-button-focus-overlay,
.mat-fab.mat-accent .mat-button-focus-overlay,
.mat-fab.mat-warn .mat-button-focus-overlay,
.mat-mini-fab.mat-primary .mat-button-focus-overlay,
.mat-mini-fab.mat-accent .mat-button-focus-overlay,
.mat-mini-fab.mat-warn .mat-button-focus-overlay {
  background-color: rgba(0, 0, 0, 0.1) !important;
}
.mat-flat-button:hover .mat-button-focus-overlay, .mat-flat-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-flat-button.cdk-program-focused .mat-button-focus-overlay,
.mat-raised-button:hover .mat-button-focus-overlay,
.mat-raised-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-raised-button.cdk-program-focused .mat-button-focus-overlay,
.mat-fab:hover .mat-button-focus-overlay,
.mat-fab.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-fab.cdk-program-focused .mat-button-focus-overlay,
.mat-mini-fab:hover .mat-button-focus-overlay,
.mat-mini-fab.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-mini-fab.cdk-program-focused .mat-button-focus-overlay {
  opacity: 1 !important;
}
@media (hover: none) {
  .mat-flat-button:hover .mat-button-focus-overlay,
.mat-raised-button:hover .mat-button-focus-overlay,
.mat-fab:hover .mat-button-focus-overlay,
.mat-mini-fab:hover .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}
.mat-flat-button.mat-button-disabled .mat-button-focus-overlay,
.mat-raised-button.mat-button-disabled .mat-button-focus-overlay,
.mat-fab.mat-button-disabled .mat-button-focus-overlay,
.mat-mini-fab.mat-button-disabled .mat-button-focus-overlay {
  opacity: 0 !important;
}

/* Transparent background buttons */
.mat-button,
.mat-icon-button,
.mat-stroked-button {
  /* Apply primary color */
  /* Apply accent color */
  /* Apply warn color */
  /* Add hover and focus styles */
  /* On primary colored buttons, use the primary color as focus overlay */
  /* On accent colored buttons, use the accent color as focus overlay */
  /* On warn colored buttons, use the warn color as focus overlay */
}
.mat-button.mat-primary:not(.mat-button-disabled) .mat-icon,
.mat-icon-button.mat-primary:not(.mat-button-disabled) .mat-icon,
.mat-stroked-button.mat-primary:not(.mat-button-disabled) .mat-icon {
  @apply text-primary !important;
}
.mat-button.mat-accent:not(.mat-button-disabled) .mat-icon,
.mat-icon-button.mat-accent:not(.mat-button-disabled) .mat-icon,
.mat-stroked-button.mat-accent:not(.mat-button-disabled) .mat-icon {
  @apply text-accent !important;
}
.mat-button.mat-warn:not(.mat-button-disabled) .mat-icon,
.mat-icon-button.mat-warn:not(.mat-button-disabled) .mat-icon,
.mat-stroked-button.mat-warn:not(.mat-button-disabled) .mat-icon {
  @apply text-warn !important;
}
.mat-button .mat-button-focus-overlay,
.mat-icon-button .mat-button-focus-overlay,
.mat-stroked-button .mat-button-focus-overlay {
  @apply bg-gray-400 bg-opacity-20 dark:bg-black dark:bg-opacity-5 !important;
}
.mat-button.mat-primary:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-icon-button.mat-primary:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button.mat-primary:not(.mat-button-disabled) .mat-button-focus-overlay {
  @apply bg-primary !important;
}
.mat-button.mat-accent:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-icon-button.mat-accent:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button.mat-accent:not(.mat-button-disabled) .mat-button-focus-overlay {
  @apply bg-accent !important;
}
.mat-button.mat-warn:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-icon-button.mat-warn:not(.mat-button-disabled) .mat-button-focus-overlay,
.mat-stroked-button.mat-warn:not(.mat-button-disabled) .mat-button-focus-overlay {
  @apply bg-warn !important;
}
.mat-button.mat-primary:not(.mat-button-disabled):hover .mat-button-focus-overlay, .mat-button.mat-primary:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay, .mat-button.mat-primary:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay, .mat-button.mat-accent:not(.mat-button-disabled):hover .mat-button-focus-overlay, .mat-button.mat-accent:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay, .mat-button.mat-accent:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay, .mat-button.mat-warn:not(.mat-button-disabled):hover .mat-button-focus-overlay, .mat-button.mat-warn:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay, .mat-button.mat-warn:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay,
.mat-icon-button.mat-primary:not(.mat-button-disabled):hover .mat-button-focus-overlay,
.mat-icon-button.mat-primary:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay,
.mat-icon-button.mat-primary:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay,
.mat-icon-button.mat-accent:not(.mat-button-disabled):hover .mat-button-focus-overlay,
.mat-icon-button.mat-accent:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay,
.mat-icon-button.mat-accent:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay,
.mat-icon-button.mat-warn:not(.mat-button-disabled):hover .mat-button-focus-overlay,
.mat-icon-button.mat-warn:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay,
.mat-icon-button.mat-warn:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay,
.mat-stroked-button.mat-primary:not(.mat-button-disabled):hover .mat-button-focus-overlay,
.mat-stroked-button.mat-primary:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay,
.mat-stroked-button.mat-primary:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay,
.mat-stroked-button.mat-accent:not(.mat-button-disabled):hover .mat-button-focus-overlay,
.mat-stroked-button.mat-accent:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay,
.mat-stroked-button.mat-accent:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay,
.mat-stroked-button.mat-warn:not(.mat-button-disabled):hover .mat-button-focus-overlay,
.mat-stroked-button.mat-warn:not(.mat-button-disabled).cdk-keyboard-focused .mat-button-focus-overlay,
.mat-stroked-button.mat-warn:not(.mat-button-disabled).cdk-program-focused .mat-button-focus-overlay {
  opacity: 0.1 !important;
}
.mat-button:hover .mat-button-focus-overlay, .mat-button.cdk-keyboard-focused .mat-button-focus-overlay, .mat-button.cdk-program-focused .mat-button-focus-overlay,
.mat-icon-button:hover .mat-button-focus-overlay,
.mat-icon-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-icon-button.cdk-program-focused .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay,
.mat-stroked-button.cdk-keyboard-focused .mat-button-focus-overlay,
.mat-stroked-button.cdk-program-focused .mat-button-focus-overlay {
  opacity: 1 !important;
}
@media (hover: none) {
  .mat-button:hover .mat-button-focus-overlay,
.mat-icon-button:hover .mat-button-focus-overlay,
.mat-stroked-button:hover .mat-button-focus-overlay {
    opacity: 0 !important;
  }
}
.mat-button.mat-button-disabled .mat-button-focus-overlay,
.mat-icon-button.mat-button-disabled .mat-button-focus-overlay,
.mat-stroked-button.mat-button-disabled .mat-button-focus-overlay {
  opacity: 0 !important;
}

/* Stroked buttons */
.mat-stroked-button {
  /* Border color */
}
.mat-stroked-button:not(.mat-button-disabled) {
  @apply border-gray-300 dark:border-gray-500 !important;
}
.mat-stroked-button.mat-button-disabled {
  @apply border-gray-200 dark:border-gray-600 !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Button Toggle
/* ----------------------------------------------------------------------------------------------------- */
.mat-button-toggle-group {
  border: none !important;
  @apply space-x-1;
}
.mat-button-toggle-group.mat-button-toggle-group-appearance-standard .mat-button-toggle + .mat-button-toggle {
  background-clip: padding-box;
}
.mat-button-toggle-group .mat-button-toggle {
  border-radius: 9999px;
  overflow: hidden;
  border: none !important;
  font-weight: 500;
}
.mat-button-toggle-group .mat-button-toggle.mat-button-toggle-checked .mat-button-toggle-label-content {
  @apply text-default !important;
}
.mat-button-toggle-group .mat-button-toggle .mat-button-toggle-label-content {
  padding: 0 20px;
  @apply text-secondary;
}
.mat-button-toggle-group .mat-button-toggle .mat-ripple {
  border-radius: 9999px;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Checkbox
/* ----------------------------------------------------------------------------------------------------- */
.mat-checkbox {
  display: inline-flex;
  /* Allow multiline text */
}
.mat-checkbox .mat-checkbox-layout {
  white-space: normal;
}
.mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container {
  display: inline-flex;
  align-items: center;
  margin: 0 8px 0 0;
  /* Add a zero-width space character to trick the container */
  /* into being the same height as a single line of the label */
}
.mat-checkbox .mat-checkbox-layout .mat-checkbox-inner-container:after {
  content: "​";
}
.mat-checkbox .mat-checkbox-layout .mat-checkbox-label {
  line-height: inherit;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Chip
/* ----------------------------------------------------------------------------------------------------- */
.mat-chip {
  font-weight: 500 !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Dialog
/* ----------------------------------------------------------------------------------------------------- */
.mat-dialog-container {
  border-radius: 16px !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Drawer
/* ----------------------------------------------------------------------------------------------------- */
.mat-drawer-backdrop.mat-drawer-shown {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Form fields
/* ----------------------------------------------------------------------------------------------------- */
/* Fuse only uses 'fill' style form fields and therefore */
/* only provides fixes and tweaks for that style */
.mat-form-field.mat-form-field-appearance-fill {
  /* Disabled */
  /* Invalid */
  /* Focused */
  /* Focused and valid fields */
  /* Disable floating mat-label */
  /* Remove the default arrow for native select */
  /* Adjustments for mat-label */
  /* Default style tweaks and enhancements */
  /* Adds better alignment for textarea inputs */
  /* Removes subscript space */
  /* Rounded */
  /* Dense */
  /* Emphasized affix */
  /* Bolder border width */
}
.mat-form-field.mat-form-field-appearance-fill.mat-form-field-disabled {
  opacity: 0.7 !important;
}
.mat-form-field.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-wrapper {
  /* Border color */
}
.mat-form-field.mat-form-field-appearance-fill.mat-form-field-invalid .mat-form-field-wrapper .mat-form-field-flex {
  @apply border-warn !important;
}
.mat-form-field.mat-form-field-appearance-fill.mat-focused .mat-form-field-wrapper {
  /* Background color */
}
.mat-form-field.mat-form-field-appearance-fill.mat-focused .mat-form-field-wrapper .mat-form-field-flex {
  @apply bg-card !important;
}
.mat-form-field.mat-form-field-appearance-fill.mat-focused:not(.mat-form-field-invalid) .mat-form-field-wrapper {
  /* Border color */
}
.mat-form-field.mat-form-field-appearance-fill.mat-focused:not(.mat-form-field-invalid) .mat-form-field-wrapper .mat-form-field-flex {
  @apply border-primary !important;
}
.mat-form-field.mat-form-field-appearance-fill.mat-form-field-has-label.mat-form-field-can-float.mat-form-field-should-float .mat-form-field-label-wrapper .mat-form-field-label {
  width: 100% !important;
  transform: none !important;
}
.mat-form-field.mat-form-field-appearance-fill.mat-form-field-type-mat-native-select .mat-form-field-infix select {
  top: auto;
  margin-top: 0;
  margin-bottom: 0;
  padding-top: 0;
  padding-right: 18px;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2364748B' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right -7px center;
  background-size: 24px;
}
.dark .mat-form-field.mat-form-field-appearance-fill.mat-form-field-type-mat-native-select .mat-form-field-infix select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%2397a6ba' viewBox='0 0 24 24'%3E%3Cpath d='M7 10l5 5 5-5H7z'/%3E%3C/svg%3E");
}
.mat-form-field.mat-form-field-appearance-fill.mat-form-field-type-mat-native-select .mat-form-field-infix:after {
  display: none;
}
.mat-form-field.mat-form-field-appearance-fill.mat-form-field-has-label .mat-form-field-wrapper {
  margin-top: 24px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper {
  margin-bottom: 16px;
  padding-bottom: 0;
  /* Remove the underline */
  /* Subscript tweaks */
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex {
  position: relative;
  display: flex;
  align-items: stretch;
  min-height: 48px;
  border-radius: 6px;
  padding: 0 16px;
  border-width: 1px;
  @apply shadow-sm bg-white border-gray-300 dark:bg-black dark:bg-opacity-5 dark:border-gray-500 !important;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon {
  margin-right: 12px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon-button {
  margin: 0 4px 0 -10px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-select {
  margin-right: 10px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-datepicker-toggle {
  margin-left: -8px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
  margin-right: 12px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon {
  margin-left: 12px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon-button {
  margin: 0 -10px 0 4px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-select {
  margin-left: 10px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-datepicker-toggle {
  margin-right: -8px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  @apply text-hint !important;
  /* Remove the margins from the mat-icon if it's inside a button */
  /* Force the icon size to 24 */
  /* Datepicker default icon size */
  /* Make mat-select usable as prefix and suffix */
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon-button,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon-button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  min-height: 40px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon-button:not(.mat-button-disabled),
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select-value,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon-button:not(.mat-button-disabled),
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select-value {
  @apply text-hint;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-raised-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-stroked-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-flat-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-fab .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-mini-fab .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-raised-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-stroked-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-flat-button .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-fab .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-mini-fab .mat-icon {
  margin: 0 !important;
  @apply icon-size-6;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-datepicker-toggle-default-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-datepicker-toggle-default-icon {
  @apply icon-size-6;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select {
  display: flex;
  align-items: center;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select:focus .mat-select-trigger .mat-select-value,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select:focus .mat-select-trigger .mat-select-value {
  @apply text-primary !important;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select:focus .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select:focus .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  border-top-color: var(--fuse-primary) !important;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select .mat-select-trigger,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select .mat-select-trigger {
  display: flex;
  align-items: center;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select .mat-select-trigger .mat-select-value,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select .mat-select-trigger .mat-select-value {
  display: flex;
  max-width: none;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select .mat-select-trigger .mat-select-value mat-select-trigger .mat-icon,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select .mat-select-trigger .mat-select-value mat-select-trigger .mat-icon {
  margin: 0 !important;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select .mat-select-trigger .mat-select-arrow-wrapper,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select .mat-select-trigger .mat-select-arrow-wrapper {
  display: flex;
  align-items: center;
  transform: none;
  margin-left: 4px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-select .mat-select-trigger .mat-select-arrow-wrapper .mat-select-arrow {
  min-height: 0;
  @apply text-gray-500 dark:text-gray-400 !important;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix {
  position: static;
  display: flex;
  align-items: center;
  width: 88px;
  padding: 0;
  border: 0;
  /* Textarea */
  /* Select */
  /* Chips */
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  padding: 14px 0;
  margin-top: 0;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix textarea.mat-input-element {
  display: flex;
  align-self: stretch;
  min-height: 36px;
  height: auto;
  margin: 14px 0;
  padding: 0 6px 0 0;
  transform: none;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select {
  display: inline-flex;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger {
  display: inline-flex;
  align-items: center;
  width: 100%;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value {
  display: flex;
  position: relative;
  max-width: none;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text {
  display: inline-flex;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-trigger .mat-select-value .mat-select-value-text > * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-arrow-wrapper {
  transform: translateY(0);
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-select .mat-select-arrow-wrapper .mat-select-arrow {
  margin: 0 0 0 8px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-chip-list {
  width: 100%;
  margin: 0 -8px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-chip-list .mat-chip-input {
  margin: 0 0 0 8px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper {
  top: -25px;
  height: auto;
  padding-top: 0;
  overflow: visible;
  pointer-events: auto;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-form-field-label-wrapper .mat-form-field-label {
  position: relative;
  top: 0;
  margin-top: 0;
  backface-visibility: hidden;
  transition: none;
  font-weight: 500;
  @apply text-default !important;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-underline {
  display: none;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  position: relative;
  top: auto;
  padding: 0;
  margin-top: 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-subscript-wrapper > div {
  display: contents;
  /* Remove the div from flow to stop the subscript animation */
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-subscript-wrapper .mat-error,
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-subscript-wrapper .mat-hint {
  display: block;
  margin-top: 4px;
}
.mat-form-field.mat-form-field-appearance-fill .mat-form-field-wrapper .mat-form-field-subscript-wrapper .mat-hint {
  @apply text-hint !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-textarea .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix,
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-textarea .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  align-items: flex-start;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-textarea .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix {
  padding-top: 12px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-textarea .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  padding-top: 12px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-no-subscript .mat-form-field-wrapper {
  padding-bottom: 0;
  margin-bottom: 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-no-subscript .mat-form-field-wrapper .mat-form-field-subscript-wrapper {
  display: none !important;
  height: 0 !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded {
  /* Emphasized affix */
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 24px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix {
  border-radius: 24px 0 0 24px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon {
  margin-right: 12px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon-button {
  margin-right: 2px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-select {
  margin-right: 8px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-datepicker-toggle {
  margin-right: 4px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
  margin-right: 12px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  border-radius: 0 24px 24px 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon {
  margin-left: 12px !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon-button {
  margin-left: 2px !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-select {
  margin-left: 12px !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-datepicker-toggle {
  margin-left: 4px !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
  margin-left: 12px !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense {
  /* Rounded */
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex {
  min-height: 40px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix .mat-icon-button,
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix .mat-icon-button {
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon-button {
  margin-left: -6px;
  margin-right: 12px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon-button {
  margin-left: 12px;
  margin-right: -6px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-infix .mat-input-element {
  padding: 11px 0;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense.fuse-mat-rounded {
  /* Emphasized affix */
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense.fuse-mat-rounded .mat-form-field-wrapper .mat-form-field-flex {
  border-radius: 20px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix {
  border-radius: 20px 0 0 20px !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-dense.fuse-mat-rounded.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  border-radius: 0 20px 20px 0 !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix {
  margin: 0 16px 0 -16px;
  padding-left: 16px;
  border-radius: 6px 0 0 6px;
  border-right-width: 1px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon {
  margin-right: 16px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-icon-button {
  margin: 0 6px 0 -10px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-select {
  margin-right: 12px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > .mat-datepicker-toggle {
  margin-right: 8px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
  margin-right: 16px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  margin: 0 -16px 0 16px;
  padding-right: 16px;
  border-radius: 0 6px 6px 0;
  border-left-width: 1px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon {
  margin-left: 16px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-icon-button {
  margin: 0 -10px 0 6px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-select {
  margin: 0 -4px 0 16px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > .mat-datepicker-toggle {
  margin-left: 8px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix > *:not(.mat-icon):not(.mat-icon-button):not(.mat-select):not(.mat-datepicker-toggle) {
  margin-left: 16px;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-prefix,
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-emphasized-affix .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-suffix {
  @apply bg-default border-gray-300 dark:border-gray-500 !important;
}
.mat-form-field.mat-form-field-appearance-fill.fuse-mat-bold .mat-form-field-wrapper .mat-form-field-flex {
  border-width: 2px !important;
}

/* Fix the outline appearance */
.mat-form-field.mat-form-field-appearance-outline .mat-form-field-wrapper .mat-form-field-flex .mat-form-field-outline {
  @apply text-gray-300 dark:text-gray-500 !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Datepicker
/* ----------------------------------------------------------------------------------------------------- */
/* Hover and active cell content background opacity */
.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused .mat-calendar-body-active > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  @apply bg-primary bg-opacity-30 !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Icon
/* ----------------------------------------------------------------------------------------------------- */
.mat-icon {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  width: 24px;
  min-width: 24px;
  height: 24px;
  min-height: 24px;
  font-size: 24px;
  line-height: 24px;
  -webkit-appearance: none !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Inputs
/* ----------------------------------------------------------------------------------------------------- */
.mat-input-element::placeholder {
  transition: none !important;
  @apply text-hint !important;
}
.mat-input-element::-moz-placeholder {
  transition: none !important;
  @apply text-hint !important;
}
.mat-input-element::-webkit-input-placeholder {
  transition: none !important;
  @apply text-hint !important;
}
.mat-input-element:-ms-input-placeholder {
  transition: none !important;
  @apply text-hint !important;
}

/* Invalid */
.mat-form-field-invalid .mat-input-element {
  /* Placeholder color */
}
.mat-form-field-invalid .mat-input-element::placeholder {
  @apply text-warn !important;
}
.mat-form-field-invalid .mat-input-element::-moz-placeholder {
  @apply text-warn !important;
}
.mat-form-field-invalid .mat-input-element::-webkit-input-placeholder {
  @apply text-warn !important;
}
.mat-form-field-invalid .mat-input-element:-ms-input-placeholder {
  @apply text-warn !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Menu
/* ----------------------------------------------------------------------------------------------------- */
.mat-menu-panel {
  min-width: 144px !important;
}
.mat-menu-panel .mat-menu-content {
  /* Divider within mat-menu */
}
.mat-menu-panel .mat-menu-content .mat-menu-item {
  display: flex;
  align-items: center;
}
.mat-menu-panel .mat-menu-content .mat-menu-item.mat-menu-item-submenu-trigger {
  padding-right: 40px;
}
.mat-menu-panel .mat-menu-content .mat-menu-item .mat-icon {
  margin-right: 12px;
}
.mat-menu-panel .mat-menu-content mat-divider {
  margin: 8px 0;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Paginator
/* ----------------------------------------------------------------------------------------------------- */
.mat-paginator .mat-paginator-container {
  padding: 8px 16px;
  justify-content: space-between;
  /* Page size select */
  /* Range actions */
}
@screen sm {
  .mat-paginator .mat-paginator-container {
    justify-content: normal;
  }
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size {
  align-items: center;
  min-height: 40px;
  margin: 8px;
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-label {
  display: none;
  margin-right: 12px;
}
@screen sm {
  .mat-paginator .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-label {
    display: block;
  }
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select {
  margin: 0;
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select .mat-form-field-wrapper {
  margin-bottom: 0;
}
.mat-paginator .mat-paginator-container .mat-paginator-page-size .mat-paginator-page-size-select .mat-form-field-wrapper .mat-form-field-flex {
  min-height: 32px;
  padding: 0 10px;
}
.mat-paginator .mat-paginator-container .mat-paginator-range-actions {
  margin: 8px 0;
}
.mat-paginator .mat-paginator-container .mat-paginator-range-actions .mat-paginator-range-label {
  margin-right: 16px;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Select
/* ----------------------------------------------------------------------------------------------------- */
.mat-select {
  display: inline-flex;
}
.mat-select .mat-select-placeholder {
  transition: none !important;
  @apply text-hint !important;
}
.mat-select .mat-select-trigger {
  display: inline-flex;
  align-items: center;
  width: 100%;
  height: auto;
}
.mat-select .mat-select-trigger .mat-select-value {
  display: flex;
  position: relative;
  max-width: none;
}
.mat-select .mat-select-trigger .mat-select-value .mat-select-value-text {
  display: inline-flex;
}
.mat-select .mat-select-trigger .mat-select-value .mat-select-value-text > * {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.mat-select .mat-select-arrow-wrapper {
  transform: translateY(0);
}
.mat-select .mat-select-arrow-wrapper .mat-select-arrow {
  margin: 0 4px 0 2px;
}

/* Invalid */
.mat-form-field-invalid .mat-select {
  /* Placeholder color */
}
.mat-form-field-invalid .mat-select .mat-select-placeholder {
  @apply text-warn !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Slide Toggle
/* ----------------------------------------------------------------------------------------------------- */
.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--fuse-accent-500-rgb), 0.54) !important;
}

.mat-slide-toggle.mat-primary.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--fuse-primary-500-rgb), 0.54) !important;
}

.mat-slide-toggle.mat-warn.mat-checked .mat-slide-toggle-bar {
  background-color: rgba(var(--fuse-warn-500-rgb), 0.54) !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Stepper
/* ----------------------------------------------------------------------------------------------------- */
.mat-step-icon {
  /* Do not override the mat-icon color */
}
.mat-step-icon .mat-icon {
  color: currentColor !important;
}

.mat-step-label,
.mat-step-label-selected {
  font-weight: 500 !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Tabs
/* ----------------------------------------------------------------------------------------------------- */
.mat-tab-group {
  /* No header */
}
.mat-tab-group.fuse-mat-no-header .mat-tab-header {
  height: 0 !important;
  max-height: 0 !important;
  border: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}
.mat-tab-group .mat-tab-header {
  border-bottom: none !important;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container {
  padding: 0 24px;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label {
  min-width: 0 !important;
  height: 40px !important;
  padding: 0 20px !important;
  border-radius: 9999px !important;
  @apply text-secondary;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label.mat-tab-label-active {
  @apply bg-gray-700 bg-opacity-10 dark:bg-gray-50 dark:bg-opacity-10 !important;
  @apply text-default !important;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label + .mat-tab-label {
  margin-left: 4px;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-tab-labels .mat-tab-label .mat-tab-label-content {
  line-height: 20px;
}
.mat-tab-group .mat-tab-header .mat-tab-label-container .mat-tab-list .mat-ink-bar {
  display: none !important;
}
.mat-tab-group .mat-tab-body-content {
  padding: 24px;
}

.mat-tab-label {
  opacity: 1 !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Textarea
/* ----------------------------------------------------------------------------------------------------- */
textarea.mat-input-element {
  box-sizing: content-box !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Toolbar
/* ----------------------------------------------------------------------------------------------------- */
.mat-toolbar {
  /* Apply primary contrast color */
  /* Apply accent contrast color */
  /* Apply warn contrast color */
}
.mat-toolbar.mat-primary .mat-icon {
  @apply text-on-primary !important;
}
.mat-toolbar.mat-primary .text-secondary {
  @apply text-on-primary text-opacity-60 !important;
}
.mat-toolbar.mat-primary .text-hint {
  @apply text-on-primary text-opacity-38 !important;
}
.mat-toolbar.mat-primary .text-disabled {
  @apply text-on-primary text-opacity-38 !important;
}
.mat-toolbar.mat-primary .divider {
  @apply text-on-primary text-opacity-12 !important;
}
.mat-toolbar.mat-accent .mat-icon {
  @apply text-on-accent !important;
}
.mat-toolbar.mat-accent .text-secondary {
  @apply text-on-accent text-opacity-60 !important;
}
.mat-toolbar.mat-accent .text-hint {
  @apply text-on-accent text-opacity-38 !important;
}
.mat-toolbar.mat-accent .text-disabled {
  @apply text-on-accent text-opacity-38 !important;
}
.mat-toolbar.mat-accent .divider {
  @apply text-on-accent text-opacity-12 !important;
}
.mat-toolbar.mat-warn .mat-icon {
  @apply text-on-warn !important;
}
.mat-toolbar.mat-warn .text-secondary {
  @apply text-on-warn text-opacity-60 !important;
}
.mat-toolbar.mat-warn .text-hint {
  @apply text-on-warn text-opacity-38 !important;
}
.mat-toolbar.mat-warn .text-disabled {
  @apply text-on-warn text-opacity-38 !important;
}
.mat-toolbar.mat-warn .divider {
  @apply text-on-warn text-opacity-12 !important;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ FullCalendar overrides
/* ----------------------------------------------------------------------------------------------------- */
.fc {
  /* Day grid event - Dragging */
}
.fc .fc-view-container {
  /* Day Grid - Month view */
  /* Time Grid - Week view */
  /* Time Grid - Day view */
  /* List - Year view */
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-head > tr > .fc-head-container {
  border: none;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header span {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  @apply text-secondary;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content {
  border: none;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-bg .fc-day {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-bg .fc-day.fc-today {
  background: none;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-day-top {
  text-align: center;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-day-top.fc-other-month {
  opacity: 1;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-day-top.fc-other-month .fc-day-number {
  @apply text-hint;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-day-top.fc-today .fc-day-number {
  @apply bg-primary text-on-primary;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-day-top .fc-day-number {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 21px;
  margin: 4px 0;
  font-size: 12px;
  border-radius: 50%;
  float: none;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-event-container .fc-day-grid-event {
  display: flex;
  align-items: center;
  height: 22px;
  min-height: 22px;
  max-height: 22px;
  margin: 0 6px 4px 6px;
  padding: 0 5px;
  font-size: 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
@screen sm {
  .fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-event-container .fc-day-grid-event {
    padding: 0 8px;
  }
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-more {
  padding: 0 3px;
  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;
  @apply text-secondary;
}
@screen sm {
  .fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-content-skeleton .fc-more {
    padding: 0 6px;
  }
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-week .fc-highlight-skeleton .fc-highlight {
  position: relative;
  opacity: 1;
  @apply bg-gray-100;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-popover {
  @apply bg-card;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-popover.fc-more-popover {
  border: none;
  border-radius: 4px;
  @apply shadow-2xl;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-popover.fc-more-popover .fc-header {
  height: 32px;
  min-height: 32px;
  max-height: 32px;
  padding: 0 8px;
  @apply bg-hover;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-popover.fc-more-popover .fc-header .fc-title {
  margin: 0;
  padding: 0;
  font-size: 12px;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-popover.fc-more-popover .fc-body {
  max-height: 160px;
  overflow: hidden auto;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-popover.fc-more-popover .fc-body .fc-event-container {
  padding: 8px;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-popover.fc-more-popover .fc-body .fc-event-container .fc-day-grid-event {
  display: flex;
  align-items: center;
  height: 22px;
  min-height: 22px;
  max-height: 22px;
  margin: 0 0 6px 0;
  padding: 0 8px;
  font-size: 12px;
  line-height: 1;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.fc .fc-view-container .fc-view.fc-dayGridMonth-view .fc-popover.fc-more-popover .fc-body .fc-event-container .fc-day-grid-event:last-child {
  margin-bottom: 0;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-head > tr > .fc-head-container {
  border: none;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-head > tr > .fc-head-container .fc-row .fc-axis {
  width: 48px !important;
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @apply text-secondary;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header span.fc-weekday {
  padding-top: 16px;
  font-size: 12px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  font-weight: 400;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header span.fc-date {
  padding-bottom: 12px;
  font-size: 26px;
  font-weight: 300;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content {
  border: none;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row {
  min-height: 0;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-bg .fc-axis {
  width: 48px !important;
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-bg .fc-day {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-bg .fc-day.fc-today {
  background: none;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 0;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-content-skeleton .fc-axis {
  width: 48px !important;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-content-skeleton .fc-event-container .fc-day-grid-event {
  display: flex;
  align-items: center;
  height: 22px;
  min-height: 22px;
  max-height: 22px;
  margin: 0 6px 6px 6px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 1;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-divider {
  border: none;
  background: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-bg .fc-axis {
  border: none;
  width: 48px !important;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-bg .fc-axis + .fc-day {
  border: none;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-bg .fc-day {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-bg .fc-day.fc-today {
  background: none;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-slats .fc-axis {
  width: 48px !important;
  height: 48px;
  text-align: center;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-slats .fc-axis span {
  font-size: 12px;
  width: 48px;
  min-width: 48px;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-slats .fc-time {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-slats .fc-widget-content {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-axis {
  width: 48px !important;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-event-container {
  margin: 0 12px 0 0;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-event-container .fc-time-grid-event {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-event-container .fc-time-grid-event .fc-time,
.fc .fc-view-container .fc-view.fc-timeGridWeek-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-event-container .fc-time-grid-event .fc-title {
  font-size: 12px;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-head > tr > .fc-head-container {
  border: none;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-head > tr > .fc-head-container .fc-row .fc-axis {
  width: 48px !important;
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  @apply text-secondary;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header span.fc-weekday {
  padding-top: 16px;
  font-size: 12px;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  font-weight: 400;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-head > tr > .fc-head-container .fc-row .fc-day-header span.fc-date {
  padding-bottom: 12px;
  font-size: 26px;
  font-weight: 300;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content {
  border: none;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-bg .fc-axis {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-bg .fc-day {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-bg .fc-day.fc-today {
  background: none;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row {
  min-height: 0;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-bg .fc-axis {
  width: 48px !important;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-content-skeleton {
  padding-bottom: 0;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-content-skeleton .fc-axis {
  width: 48px !important;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-day-grid .fc-row .fc-content-skeleton .fc-event-container .fc-day-grid-event {
  display: flex;
  align-items: center;
  height: 22px;
  min-height: 22px;
  max-height: 22px;
  margin: 0 6px 6px 6px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 1;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-divider {
  border: none;
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-bg .fc-day {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-bg .fc-day.fc-today {
  background: none;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-bg .fc-axis {
  border: none;
  width: 48px !important;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-bg .fc-axis + .fc-day {
  border: none;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-slats .fc-axis {
  width: 48px !important;
  height: 48px;
  text-align: center;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-slats .fc-axis span {
  font-size: 12px;
  width: 48px;
  min-width: 48px;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-slats .fc-time {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-slats .fc-widget-content {
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-axis {
  width: 48px !important;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-event-container {
  margin: 0 12px 0 0;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-event-container .fc-time-grid-event {
  display: flex;
  padding: 8px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
}
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-event-container .fc-time-grid-event .fc-time,
.fc .fc-view-container .fc-view.fc-timeGridDay-view .fc-body > tr > .fc-widget-content .fc-time-grid .fc-content-skeleton .fc-event-container .fc-time-grid-event .fc-title {
  font-size: 12px;
}
.fc .fc-view-container .fc-view.fc-listYear-view {
  border: none;
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-heading {
  display: none;
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item {
  display: flex;
  cursor: pointer;
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item:hover td {
  @apply bg-hover;
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td {
  display: flex;
  align-items: center;
  width: auto;
  height: 48px;
  min-height: 48px;
  padding: 0 8px;
  border-width: 0 0 1px 0;
  border-color: var(--fuse-divider);
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-date {
  order: 1;
  padding-left: 16px;
  width: 100px;
  min-width: 100px;
  max-width: 100px;
}
@screen sm {
  .fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-date {
    width: 120px;
    min-width: 120px;
    max-width: 120px;
  }
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-date > span {
  display: flex;
  align-items: baseline;
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-date > span span:first-child {
  display: flex;
  justify-content: center;
  padding-right: 2px;
  width: 32px;
  min-width: 32px;
  max-width: 32px;
  font-size: 18px;
}
@screen sm {
  .fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-date > span span:first-child {
    padding-right: 8px;
  }
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-date > span span:first-child + span {
  display: flex;
  font-size: 11px;
  font-weight: 500;
  letter-spacing: 0.055em;
  text-transform: uppercase;
  @apply text-secondary;
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-time {
  flex: 0 0 auto;
  order: 3;
  width: 120px;
  min-width: 120px;
  max-width: 120px;
}
@screen sm {
  .fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-time {
    width: 160px;
    min-width: 160px;
    max-width: 160px;
  }
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-marker {
  flex: 0 0 auto;
  order: 2;
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-marker .fc-event-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.fc .fc-view-container .fc-view.fc-listYear-view .fc-list-table .fc-list-item td.fc-list-item-title {
  flex: 1 1 auto;
  order: 4;
  padding-right: 24px;
  font-weight: 500;
}
.fc .fc-day-grid-event.fc-dragging, .fc .fc-day-grid-event.fc-resizing {
  display: flex;
  align-items: center;
  height: 22px;
  min-height: 22px;
  max-height: 22px;
  margin: 0 6px 4px 6px;
  padding: 0 8px;
  font-size: 12px;
  line-height: 1;
  border-radius: 4px;
  border: none;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Highlight.js overrides
/* ----------------------------------------------------------------------------------------------------- */
code[class*=language-] .hljs-comment,
code[class*=language-] .hljs-quote,
pre[class*=language-] .hljs-comment,
pre[class*=language-] .hljs-quote {
  color: #8B9FC1;
  font-style: italic;
}
code[class*=language-] .hljs-doctag,
code[class*=language-] .hljs-keyword,
code[class*=language-] .hljs-formula,
pre[class*=language-] .hljs-doctag,
pre[class*=language-] .hljs-keyword,
pre[class*=language-] .hljs-formula {
  color: #22D3EE;
}
code[class*=language-] .hljs-name,
pre[class*=language-] .hljs-name {
  color: #E879F9;
}
code[class*=language-] .hljs-tag,
pre[class*=language-] .hljs-tag {
  color: #BAE6FD;
}
code[class*=language-] .hljs-section,
code[class*=language-] .hljs-selector-tag,
code[class*=language-] .hljs-deletion,
code[class*=language-] .hljs-subst,
pre[class*=language-] .hljs-section,
pre[class*=language-] .hljs-selector-tag,
pre[class*=language-] .hljs-deletion,
pre[class*=language-] .hljs-subst {
  color: #F87F71;
}
code[class*=language-] .hljs-literal,
pre[class*=language-] .hljs-literal {
  color: #36BEFF;
}
code[class*=language-] .hljs-string,
code[class*=language-] .hljs-regexp,
code[class*=language-] .hljs-addition,
code[class*=language-] .hljs-attribute,
code[class*=language-] .hljs-meta-string,
pre[class*=language-] .hljs-string,
pre[class*=language-] .hljs-regexp,
pre[class*=language-] .hljs-addition,
pre[class*=language-] .hljs-attribute,
pre[class*=language-] .hljs-meta-string {
  color: #BEF264;
}
code[class*=language-] .hljs-built_in,
code[class*=language-] .hljs-class .hljs-title,
pre[class*=language-] .hljs-built_in,
pre[class*=language-] .hljs-class .hljs-title {
  color: #FFD374;
}
code[class*=language-] .hljs-attr,
code[class*=language-] .hljs-variable,
code[class*=language-] .hljs-template-variable,
code[class*=language-] .hljs-type,
code[class*=language-] .hljs-selector-class,
code[class*=language-] .hljs-selector-attr,
code[class*=language-] .hljs-selector-pseudo,
code[class*=language-] .hljs-number,
pre[class*=language-] .hljs-attr,
pre[class*=language-] .hljs-variable,
pre[class*=language-] .hljs-template-variable,
pre[class*=language-] .hljs-type,
pre[class*=language-] .hljs-selector-class,
pre[class*=language-] .hljs-selector-attr,
pre[class*=language-] .hljs-selector-pseudo,
pre[class*=language-] .hljs-number {
  color: #22D3EE;
}
code[class*=language-] .hljs-symbol,
code[class*=language-] .hljs-bullet,
code[class*=language-] .hljs-link,
code[class*=language-] .hljs-meta,
code[class*=language-] .hljs-selector-id,
code[class*=language-] .hljs-title,
pre[class*=language-] .hljs-symbol,
pre[class*=language-] .hljs-bullet,
pre[class*=language-] .hljs-link,
pre[class*=language-] .hljs-meta,
pre[class*=language-] .hljs-selector-id,
pre[class*=language-] .hljs-title {
  color: #E879F9;
}
code[class*=language-] .hljs-emphasis,
pre[class*=language-] .hljs-emphasis {
  font-style: italic;
}
code[class*=language-] .hljs-strong,
pre[class*=language-] .hljs-strong {
  font-weight: 700;
}
code[class*=language-] .hljs-link,
pre[class*=language-] .hljs-link {
  text-decoration: underline;
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Perfect scrollbar overrides
/* ----------------------------------------------------------------------------------------------------- */
.ps {
  position: relative;
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y, .ps.ps--focus > .ps__rail-x,
.ps.ps--focus > .ps__rail-y, .ps.ps--scrolling-x > .ps__rail-x,
.ps.ps--scrolling-x > .ps__rail-y, .ps.ps--scrolling-y > .ps__rail-x,
.ps.ps--scrolling-y > .ps__rail-y {
  opacity: 1;
}
.ps > .ps__rail-x,
.ps > .ps__rail-y {
  z-index: 99999;
}
.ps > .ps__rail-x {
  height: 14px;
  background: transparent !important;
  transition: none !important;
}
.ps > .ps__rail-x:hover, .ps > .ps__rail-x:focus, .ps > .ps__rail-x.ps--clicking {
  opacity: 1;
}
.ps > .ps__rail-x:hover .ps__thumb-x, .ps > .ps__rail-x:focus .ps__thumb-x, .ps > .ps__rail-x.ps--clicking .ps__thumb-x {
  height: 10px;
}
.ps > .ps__rail-x .ps__thumb-x {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  height: 6px;
  transition: height 225ms cubic-bezier(0.25, 0.8, 0.25, 1);
}
.ps > .ps__rail-y {
  width: 14px;
  background: transparent !important;
  transition: none !important;
  left: auto !important;
}
.ps > .ps__rail-y:hover, .ps > .ps__rail-y:focus, .ps > .ps__rail-y.ps--clicking {
  opacity: 1;
}
.ps > .ps__rail-y:hover .ps__thumb-y, .ps > .ps__rail-y:focus .ps__thumb-y, .ps > .ps__rail-y.ps--clicking .ps__thumb-y {
  width: 10px;
}
.ps > .ps__rail-y .ps__thumb-y {
  background: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.15);
  width: 6px;
  transition: width 225ms cubic-bezier(0.25, 0.8, 0.25, 1);
}

/* ----------------------------------------------------------------------------------------------------- */
/*  @ Quill editor overrides
/* ----------------------------------------------------------------------------------------------------- */
.ql-toolbar {
  border-radius: 6px 6px 0 0;
  padding: 0 !important;
  @apply bg-gray-100;
  @apply border-gray-300 border-opacity-100 !important;
}
.dark .ql-toolbar {
  background-color: rgba(0, 0, 0, 0.05);
  @apply border-gray-500 !important;
}
.ql-toolbar .ql-formats {
  margin: 11px 8px !important;
}
.ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
  @apply border-gray-300;
}
.dark .ql-toolbar .ql-picker.ql-expanded .ql-picker-label {
  @apply border-gray-500;
}
.ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  z-index: 10 !important;
  @apply border-gray-300 bg-card;
}
.dark .ql-toolbar .ql-picker.ql-expanded .ql-picker-options {
  @apply border-gray-500;
}
.ql-toolbar .ql-picker .ql-picker-label {
  @apply text-default;
}
.ql-toolbar .ql-picker .ql-picker-options .ql-picker-item {
  @apply text-default;
}
.ql-toolbar .ql-stroke,
.ql-toolbar .ql-stroke-mitter {
  stroke: var(--fuse-icon);
}
.ql-toolbar .ql-fill {
  fill: var(--fuse-icon);
}
.ql-toolbar button:hover,
.ql-toolbar button:focus,
.ql-toolbar button.ql-active,
.ql-toolbar .ql-picker-label:hover,
.ql-toolbar .ql-picker-label.ql-active,
.ql-toolbar .ql-picker-item:hover,
.ql-toolbar .ql-picker-item.ql-selected {
  @apply text-primary !important;
}
.ql-toolbar button:hover .ql-stroke,
.ql-toolbar button:hover .ql-stroke-mitter,
.ql-toolbar button:focus .ql-stroke,
.ql-toolbar button:focus .ql-stroke-mitter,
.ql-toolbar button.ql-active .ql-stroke,
.ql-toolbar button.ql-active .ql-stroke-mitter,
.ql-toolbar .ql-picker-label:hover .ql-stroke,
.ql-toolbar .ql-picker-label:hover .ql-stroke-mitter,
.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-toolbar .ql-picker-label.ql-active .ql-stroke-mitter,
.ql-toolbar .ql-picker-item:hover .ql-stroke,
.ql-toolbar .ql-picker-item:hover .ql-stroke-mitter,
.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-mitter {
  stroke: var(--fuse-primary) !important;
}
.ql-toolbar button:hover .ql-fill,
.ql-toolbar button:focus .ql-fill,
.ql-toolbar button.ql-active .ql-fill,
.ql-toolbar .ql-picker-label:hover .ql-fill,
.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-toolbar .ql-picker-item:hover .ql-fill,
.ql-toolbar .ql-picker-item.ql-selected .ql-fill {
  fill: var(--fuse-primary) !important;
}

.ql-container {
  overflow: hidden;
  border-radius: 0 0 6px 6px;
  @apply border-gray-300 border-opacity-100 shadow-sm !important;
}
.dark .ql-container {
  @apply border-gray-500 !important;
}
.ql-container .ql-editor {
  min-height: 160px;
  max-height: 160px;
  height: 160px;
  @apply bg-card;
}
.dark .ql-container .ql-editor {
  background-color: rgba(0, 0, 0, 0.05);
}
.ql-container .ql-editor.ql-blank::before {
  @apply text-hint;
}